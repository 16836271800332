import React from "react";

import {
  StandardText,
  StandardTextProps,
} from "src/components/global/standardText";
import {
  ProductGrid,
  ProductGridProps,
} from "src/components/global/productGrid";
import {
  NestedPages,
  NestedPagesProps,
} from "src/components/global/nestedPages";
import SectionModule from "src/components/SectionModule";
import HeroSection from "src/containers/HeroSection";
import HeadingSection from "src/containers/HeadingSection";
import ImageRowSection from "src/containers/ImageRowSection";
import ProductListSection from "src/containers/ProductListSection";
import TestimonialSection from "src/containers/TestimonialSection";
import EndorsementSection from "src/containers/EndorsementSection";
import TextIconSection from "src/containers/TextIconSection";
import InstagramListSection from "src/containers/InstagramListSection";
import BoughtTogetherSection from "src/containers/BoughtTogetherSection";
import ListImageSection from "src/containers/ListImageSection";
import TestimonialList from "src/containers/TestimonialList";
import TestimonialCards from "src/containers/TestimonialCards";
import WhatYouGet from "src/containers/WhatYouGet";
import QuestionAnswer from "src/containers/QuestionAnswer";
import ProductReviews from "src/containers/ProductReviews";
import CustomerQuestions from "src/containers/CustomerQuestions";
import CarouselList from "src/containers/CarouselList";
import ExtrudedImageText from "src/containers/ExtrudedImageText";
import BackgroundText from "src/containers/BackgroundText";
import ImageText from "src/containers/ImageText";
import LongImageText from "src/containers/LongImageText";
import InformationSection from "src/containers/InformationSection";
import ModelSection from "src/containers/ModelSection";
import ProductListDescription from "src/containers/ProductListDescription";
import ImageHeadingSection from "src/containers/ImageHeading";
import ReviewsAndFAQSection from "src/containers/ReviewsAndFAQSection";
import AnnouncementBar from "./AnnouncementBar";
import VideosRow from "./VideosRow";
import ProductWithFAQSection from "src/containers/ProductWithFAQSection";
import NewImageText from "src/containers/NewImageText";
import TwoImagesRow from "src/containers/TwoImagesRow";
import BundleSection from "src/containers/BundleSection";
import AnnouncementBarProduct from "./AnnouncementBarProduct";
import BlogCategories from "./BlogCategories";
import BlogGroup from "./BlogGroup";
import BlogForm from "./Forms/BlogForm";
import RichText from "./RichText";
import CarouselReviews from "src/containers/CarouselReviews";
import TextVideoIconsSection from "src/containers/TextVideoIconsSection";
import PDPFAQSection from "src/containers/PDPFaqSection";
import NewTextIconSection from "src/containers/NewTextIconSection";
import NewFAQSection from "src/containers/NewFAQSection";
import NewImageRowSection from "src/containers/NewImageRowSection";
import NewHeroSection from "src/containers/NewHeroSection";
import ComparisonSection from "src/containers/ComparionSection";
import NewTextImageBox from "src/containers/NewTextImageBox";
import NewTestimonialList from "src/containers/NewTestimonialList";
import ProductInformationsSection from "src/containers/ProductInformations";
import { BestSellingProductsSlider } from "./BestSellingProductsSlider";

export const Modules = ({
  reactModule,
  type,
  context,
}: {
  type: string;
  reactModule: any;
  context: any;
}) => {
  switch (type) {
    // case "standardText":
    //   return <StandardText data={reactModule as StandardTextProps["data"]} />;
    // case "productGrid":
    //   return <ProductGrid data={reactModule as ProductGridProps["data"]} />;
    // case "nestedPages":
    //   return <NestedPages data={reactModule as NestedPagesProps["data"]} />;
    case "productWithFaqs":
      return (
        <SectionModule
          data={reactModule}
          Component={ProductWithFAQSection}
          context={context}
        />
      );
    case "videosRow":
      return (
        <SectionModule
          data={reactModule}
          Component={VideosRow}
          context={context}
        />
      );
    case "announcementBar":
      return (
        <SectionModule
          data={reactModule}
          Component={AnnouncementBar}
          context={context}
        />
      );
    case "reviewsAndFaqs":
      return (
        <SectionModule
          data={reactModule}
          Component={ReviewsAndFAQSection}
          context={context}
        />
      );
    case "heroSection":
      return (
        <SectionModule
          data={reactModule}
          Component={HeroSection}
          context={context}
        />
      );
    case "headingSection":
      return (
        <SectionModule
          data={reactModule}
          Component={HeadingSection}
          context={context}
        />
      );
    case "imageRowSection":
      return (
        <SectionModule
          data={reactModule}
          Component={ImageRowSection}
          context={context}
        />
      );
    case "bestSellingProductsSlider": 
        return (
          <SectionModule 
            data={reactModule}
            Component={BestSellingProductsSlider}
            context={context}
          />
        )
    case "productList":
      return (
        <SectionModule
          data={reactModule}
          Component={ProductListSection}
          context={context}
        />
      );
      case "productInformations":
        return (
          <SectionModule
            data={reactModule}
            Component={ProductInformationsSection}
            context={context}
          />
        );
    case "testimonial":
      return (
        <SectionModule
          data={reactModule}
          Component={TestimonialSection}
          context={context}
        />
      );
    case "endorsement":
      return (
        <SectionModule
          data={reactModule}
          Component={EndorsementSection}
          context={context}
        />
      );
    case "textIcon":
      return (
        <SectionModule
          data={reactModule}
          Component={TextIconSection}
          context={context}
        />
      );
      case "textVideoIcons":
      return (
        <SectionModule
          data={reactModule}
          Component={TextVideoIconsSection}
          context={context}
        />
      );
    case "instagramList":
      return (
        <SectionModule
          data={reactModule}
          Component={InstagramListSection}
          context={context}
        />
      );
    case "boughtTogether":
      return (
        <SectionModule
          data={reactModule}
          Component={BoughtTogetherSection}
          context={context}
        />
      );
    case "listImage":
      return (
        <SectionModule
          data={reactModule}
          Component={ListImageSection}
          context={context}
        />
      );
    case "testimonialList":
      return (
        <SectionModule
          data={reactModule}
          Component={TestimonialList}
          context={context}
        />
      );
      case "testimonialCards":
        return (
          <SectionModule
            data={reactModule}
            Component={TestimonialCards}
            context={context}
          />
        );
    case "whatYouGet":
      return (
        <SectionModule
          data={reactModule}
          Component={WhatYouGet}
          context={context}
        />
      );
    case "questionAnswerSection":
      return (
        <SectionModule
          data={reactModule}
          Component={QuestionAnswer}
          context={context}
        />
      );
    case "productReviews":
      return (
        <SectionModule
          data={reactModule}
          Component={ProductReviews}
          context={context}
        />
      );
    case "customerQuestions":
      return (
        <SectionModule
          data={reactModule}
          Component={CustomerQuestions}
          context={context}
        />
      );
    case "carouselList":
      return (
        <SectionModule
          data={reactModule}
          Component={CarouselList}
          context={context}
        />
      );
    case "carouselReviews":
      return (
        <SectionModule
          data={reactModule}
          Component={CarouselReviews}
          context={context}
        />
      );
    case "extrudedImageText":
      return (
        <SectionModule
          data={reactModule}
          Component={ExtrudedImageText}
          context={context}
        />
      );
    case "backgroundText":
      return (
        <SectionModule
          data={reactModule}
          Component={BackgroundText}
          context={context}
        />
      );
    case "imageText":
      return (
        <SectionModule
          data={reactModule}
          Component={ImageText}
          context={context}
        />
      );
    case "newImageText":
      return (
        <SectionModule
          data={reactModule}
          Component={NewImageText}
          context={context}
        />
      );
    case "longImageText":
      return (
        <SectionModule
          data={reactModule}
          Component={LongImageText}
          context={context}
        />
      );
    case "informationSection":
      return (
        <SectionModule
          data={reactModule}
          Component={InformationSection}
          context={context}
        />
      );
    case "modelSection":
      return (
        <SectionModule
          data={reactModule}
          Component={ModelSection}
          context={context}
        />
      );
    case "productListDescription":
      return (
        <SectionModule
          data={reactModule}
          Component={ProductListDescription}
          context={context}
        />
      );
    case "imageHeading":
      return (
        <SectionModule
          data={reactModule}
          Component={ImageHeadingSection}
          context={context}
        />
      );
    case "twoImagesRow":
      return <SectionModule data={reactModule} Component={TwoImagesRow} />;
    case "bundleSection":
      return (
        <SectionModule
          data={reactModule}
          Component={BundleSection}
          context={context}
        />
      );
    case "announcementBarProduct":
      return (
        <SectionModule
          data={reactModule}
          Component={AnnouncementBarProduct}
          context={context}
        />
      );
    case "embedHTML":
      return <div dangerouslySetInnerHTML={{ __html: reactModule?.html }} />;
    case "blogCategories":
      return (
        <SectionModule
          data={reactModule}
          Component={BlogCategories}
          context={context}
        />
      );
    case "blogGroup":
      return (
        <SectionModule
          data={reactModule}
          Component={BlogGroup}
          context={context}
        />
      );
    case "form":
      return (
        <SectionModule
          data={reactModule}
          Component={BlogForm}
          context={context}
        />
      );
      case "richText":
        return (
          <SectionModule
            data={reactModule}
            Component={RichText}
            context={context}
          />
        );
        case "pdpQuestionAnswerSection":
          return (
            <SectionModule
              data={reactModule}
              Component={PDPFAQSection}
              context={context}
            />
          ); 
      case "newTextIcon":
        return (
          <SectionModule
            data={reactModule}
            Component={NewTextIconSection}
            context={context}
          />
        );
      case "newQuestionAnswerSection":
        return (
          <SectionModule
            data={reactModule}
            Component={NewFAQSection}
            context={context}
          />
        );
      case "newImageRowSection":
        return (
          <SectionModule
            data={reactModule}
            Component={NewImageRowSection}
            context={context}
          />
        );
      case "newHeroSection":
        return (
          <SectionModule
            data={reactModule}
            Component={NewHeroSection}
            context={context}
          />
        );
      case "comparisonSection":
        return (
          <SectionModule
            data={reactModule}
            Component={ComparisonSection}
            context={context}
          />
        );
      case "newTextImageBox":
          return (
            <SectionModule
              data={reactModule}
              Component={NewTextImageBox}
              context={context}
            />
          );
      case "newTestimonialList":
            return (
              <SectionModule
                data={reactModule}
                Component={NewTestimonialList}
                context={context}
              />
            );
    default:
      return null;
    // return <span>{type}</span>;
  }
};

import React, { useEffect, useState } from 'react';
import ProductForm from 'src/components/ProductForm';
import { useTheme } from 'styled-components';
import {
  StyledContainer,
  Title,
  Price,
  RatingsBox,
  TopIconList,
  StyledRatingDesktop,
  RatingParagraph,
  Description,
  StyledBlocks,
  PriceBox,
  ComparePrice,
  TotalReviews,
  Breed,
  DisText,
  AdditionalInformation,
  KlarnaBox,
  StyledImage,
  StyledImages,
  Message,
  OkendoReviews,
  PulsingGreen,
  PulsingRed,
  ProductValues,
  ImageWrap,
  Value,
  NewProductMessage,
  DisLabel
} from "./styles";
import { SanityImage } from "src/interfaces/sanity";
import { Link } from "gatsby";
// import { useAdditionalInformationDrawer } from '../../context/siteContext'
import TrustPilot from "src/images/Trustpilotlogo.png";
import TPLogo from "src/images/tpLog.png";
import Klarna from "src/images/klarnaa.svg";
import Cancel from "src/images/cancel.svg";
import Sezzle from "src/images/sezzle.svg";
import SezzleColor from "src/images/Sezzle-color.svg";
import Klarnaa from "src/images/Color-Logo.jpg";
import Icon from "src/images/giftt.svg";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import CountdownTimer from "src/containers/CountdownTimer";
import TestimonialPDP from 'src/containers/TestimonialPDP';
import AnnouncementBarProduct from "src/components/AnnouncementBarProduct";
import { toUpperCamelCase } from 'src/helpers/toUpperCamelCase';
import RichText from '../RichText';
import valueCheck from 'src/images/value-check.svg';
import locales from 'src/locales';

interface Props {
  id: string;
  title: string;
  sku: string;
  okendoReviews: {
    totalReviews: number;
    rating: nummber;
  };
  type: any;
  variantTitle: string;
  price: string;
  priceV2?: object;
  productId: number;
  iconListTop: any;
  iconListBottom: any;
  bundles?: [];
  pillowcases?: [];
  productQuantity?: [];
  quantityText?: string;
  caseText?: string;
  caseSubheader?: [];
  activePack: any;
  setProductPack: any;
  removeProductPack: any;
  addQuantity: any;
  removeQuantity: any;
  removeQuantityBundle: any;
  quantity: any;
  setQuantity: any;
  description?: [];
  productValues?: [];
  openReviews: () => void;
  openMoneyBack: () => void;
  rating?: number;
  totalReviews: number;
  notificationBar: boolean;
  mobilePhoto?: SanityImage;
  atcButton?: {
    caption?: string;
    price?: boolean;
  };
  slug: {
    current: string;
  };
  setOpenUpsell: (state: boolean) => void;
  productTestimonial: any;
  upsellReviews?: [];
  bundleIcon: any;
  bundleText: string;
  bundleSubheader: string;
  showDeliveryTimeWidget: boolean;
  showCountdown: boolean;
  date: any;
  newProductMessage: boolean;
  showTestimonial: boolean;
  showBuyNowButton: boolean;
  announcementBarProduct: any;
  enableProdQuantity?: boolean;
  enableProdBundles?: boolean;
}


const ProductInfo = ({
  id,
  slug,
  title,
  sku,
  okendoReviews,
  type,
  variantTitle,
  price,
  discountLabel,
  priceV2,
  productId,
  iconListTop,
  iconListBottom,
  bundles,
  pillowcases,
  productQuantity,
  quantityText,
  caseText,
  caseSubheader,
  activePack,
  setActivePack,
  setProductPack,
  removeProductPack,
  addQuantity,
  removeQuantity,
  removeQuantityBundle,
  quantity,
  setQuantity,
  openReviews,
  rating,
  totalReviews,
  notificationBar,
  mobilePhoto,
  atcButton,
  description,
  productValues,
  additionalInformationTitle,
  mainImage,
  productTestimonial,
  upsells,
  upsellReviews,
  bundleIcon,
  bundleText,
  bundleSubheader,
  showPayment,
  paymentImage,
  showTrialMessage,
  showDeliveryTimeWidget,
  showCountdown,
  date,
  showTestimonial,
  newProductMessage,
  showBuyNowButton,
  announcementBarProduct,
  enableProdBundles,
  enableProdQuantity

}: Props) => {
  // const { openAdditionalInformation } = useAdditionalInformationDrawer();
  const theme = useTheme();
  const [savedMoney, setSavedMoney] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState<string | undefined>(
    price
  );
  const [comparePrice, setComparePrice] = useState<string | undefined>(
    undefined
  );
  const isUK = process.env.GATSBY_CURRENCY_SYMBOL !== "$";
  const [discount, setDiscount] = useState<number>(0);
  const [pillowcasePrice, setPillowcasePrice] = useState<number>(0);
  const getPrice = () => {
    const symbol = process.env.GATSBY_CURRENCY_SYMBOL;
    const bundleLength = productQuantity?.length;
    const lastItemPrice = productQuantity?.[bundleLength - 1]?.salesPrice;
  
    if (bundleLength < quantity && isUK && slug?.current == 'easy-sleeper') {
      const calculatedPriceWithDiscounts = (price * 1) + ((quantity - 1 ) * (price - productQuantity[1]?.savingPrice));
      return `${symbol}${calculatedPriceWithDiscounts.toFixed(2)}`;
    }

    if (bundleLength < quantity) {
      const quantityOfNoDiscountProducts = quantity - bundleLength;
      const calculatedPriceWithDiscounts = (price * quantityOfNoDiscountProducts) + lastItemPrice;
      return `${symbol}${calculatedPriceWithDiscounts.toFixed(2)}`;
    }
  
    if (bundleLength && quantity !== 0) {
      const totalPrice = productQuantity[quantity - 1]?.salesPrice + pillowcasePrice;
      return `${symbol}${totalPrice.toFixed(2)}`;
    }
  
    if (quantity === 0 && pillowcasePrice !== 0) {
      return `${symbol}${pillowcasePrice.toFixed(2)}`;
    }
  
    if (activePack) {
      return `${symbol}${activePack.price}`;
    }
  
    if (quantity !== 0 && pillowcasePrice === 0) {
      const totalPrice = price * quantity;
      return `${symbol}${totalPrice.toFixed(2)}`;
    }
  };
  

  let productPrice = parseFloat(discountedPrice * quantity).toFixed(2);

  let message = locales.productInfo.new_product_message;
  if (id == 6852326654038 || id == 6852243685462) {
      message = locales.productInfo.moreStock;
  }


  return (
    <StyledContainer notificationBar={notificationBar}>
      <div id="variant-hide-mobile">
        <Breed>
          <span>
            <Link to="/">Home</Link>
          </span>
          <span> / </span>
          <span>{toUpperCamelCase(slug.current)}</span>
        </Breed>
      </div>
      <div id="variant-hide-mobile">

      {
        newProductMessage
        ? <NewProductMessage>{message}</NewProductMessage>
        : <RatingsBox onClick={openReviews}>
          <StyledRatingDesktop fieldName="productInfo" number={okendoReviews?.rating} color={theme.colors.yellow} />
          <RatingParagraph>
            <span>Rated {okendoReviews?.rating} out of 5 by {okendoReviews?.totalReviews} happy customers</span>
          </RatingParagraph>
        </RatingsBox>
      }
      
      </div>
      <div id="variant-hide-mobile">
        <Title>{title}</Title>

      </div>

      <div id="trustpilot-logo" className="trustpilot">
        <strong className="text">Excellent</strong>
        <div className="tp-logo-container">
          <img src={TPLogo} alt="TP logo" />
        </div>
        <strong>1,585</strong> <span>reviews on </span>
        <div className="tp-logo">
          <img src={TrustPilot} alt="TP logo" />
        </div>
      </div>

      
        <PriceBox>
          {productQuantity?.length > 0 && quantity > 0 && (
            <>
              {parseFloat(
                productQuantity[quantity - 1]?.salesPrice + pillowcasePrice
              ).toFixed(2) !== parseFloat(
                productQuantity[quantity - 1]?.originPrice + pillowcasePrice
              ).toFixed(2) && (
                  <ComparePrice>
                    {`${process.env.GATSBY_CURRENCY_SYMBOL} ${parseFloat(
                      productQuantity[quantity - 1]?.originPrice + pillowcasePrice
                    ).toFixed(2)}`}
                  </ComparePrice>
                )}
                {quantity > 2 && isUK && slug?.current == 'easy-sleeper' &&
                <ComparePrice>
                    {`${process.env.GATSBY_CURRENCY_SYMBOL} ${parseFloat(
                      productPrice
                    ).toFixed(2)}`}
                </ComparePrice>}
            </>
          )}
          <Price>{getPrice()}</Price>
          {comparePrice && comparePrice?.amount != price && !productQuantity?.length && (
            <ComparePrice>
              {process.env.GATSBY_CURRENCY_SYMBOL +
                parseFloat(comparePrice?.amount * quantity).toFixed(2)}
            </ComparePrice>
          )}
          {isUK && slug?.current == 'easy-sleeper' && quantity > 0 && <DisLabel>{discountLabel}</DisLabel>} 
          {enableProdQuantity && productQuantity[quantity - 1]?.discountText && <DisText className={`${isUK && slug?.current == 'easy-sleeper' ? "discount-variant-A" : ""}`}>{productQuantity[quantity - 1]?.discountText}</DisText>}
          {isUK && slug?.current == 'easy-sleeper' && quantity > 0 && <DisText className="discount-variant-B">2 for £50 - save 15%</DisText>} 
          {quantity > 0 && discount > 0 && productQuantity[quantity - 1]?.savingPrice > 0 && productQuantity[quantity - 1]?.discountText == null && (
              <DisText className={`${isUK && slug?.current == 'easy-sleeper' ? "discount-variant-A" : ""}`}>{productQuantity[quantity - 1]?.discount}% OFF</DisText>
          )}
        </PriceBox>
        

    

      {description && (
        <Description>
          <StyledBlocks blocks={description} />
        </Description>
      )}
      <ProductValues>
        {productValues?.map((value) =>  (
          <Value>
            <ImageWrap>
              <img src={valueCheck} alt="Value Check"/>
            </ImageWrap>
            <RichText data={value} />
          </Value>
        ))}
      </ProductValues>  
      <div id={slug?.current == 'easy-sleeper' ? "hideIconsOnEasySleeper" : "showMobile"}>
        {iconListTop && (
          <TopIconList 
            direction={iconListTop.direction}
            list={iconListTop.list}
            bigIcons={iconListTop.bigIcons}
          />
        )}
      </div>

      <TestimonialPDP showTestimonial={showTestimonial} />

      <CountdownTimer date={date} showCountdown={showCountdown} />
      
       {(showTrialMessage) && (
        <div className="free-trial-message" id={process.env.GATSBY_CURRENCY_SYMBOL === "$" ? "free-trial-message" :""}>
          <Message>
          <span className="title-icon-box">
              <img src={Icon} alt="icon" />
              <span className="mobileFree" style={{ fontSize: '16px', fontWeight: '700' }}>FREE GIFT!</span>
            </span>
            <p style={{ fontWeight: "500", color: '#0E0822' }} className="description"><span className='desktopFreeGift'>FREE GIFT!</span> <br/>{locales?.page?.product?.gift} </p>
             
          </Message>
        </div>
      )}

      <ProductForm
        id={id}
        title={title}
        sku={sku}
        type={type}
        variantTitle={variantTitle}
        slug={slug}
        productId={productId}
        defaultPrice={price}
        showQuantity={true}
        activePack={activePack}
        removePack={removeProductPack}
        setActivePack={setActivePack}
        addQuantity={addQuantity}
        quantity={quantity}
        removeQuantity={removeQuantity}
        removeQuantityBundle={removeQuantityBundle}
        setQuantity={setQuantity}
        mobilePhoto={mobilePhoto}
        atcButton={atcButton}
        setComparePrice={setComparePrice}
        setDiscountedPrice={setDiscountedPrice}
        discountedPrice={discountedPrice}
        mainImage={mainImage}
        upsellData={upsells}
        upsellReviews={upsellReviews}
        bundles={bundles}
        pillowcases={pillowcases}
        caseText={caseText}
        caseSubheader={caseSubheader}
        setSavedMoney={setSavedMoney}
        setDiscount={setDiscount}
        discount={discount}
        bundleIcon={bundleIcon}
        bundleText={bundleText}
        bundleSubheader={bundleSubheader}
        productQuantity={productQuantity}
        quantityText={quantityText}
        pillowcasePrice={setPillowcasePrice}
        showDeliveryTimeWidget={showDeliveryTimeWidget}
        showBuyNowButton={showBuyNowButton}
        enableProdQuantity={enableProdQuantity}
        enableProdBundles={enableProdBundles}
      />
      <AnnouncementBarProduct id="variant-b" data={announcementBarProduct} />
    </StyledContainer>
  );
};

export default ProductInfo;

import styled from "styled-components";
import IconList from "src/components/IconList";
import Rating from "src/components/Rating";
import { Paragraph, SectionHeading, Heading } from "src/components/Text";
import { ListItem, Icon, Text } from "../IconList/styles";
import BlockContent from "src/components/BlockContent";
import Link from "src/components/Link";
import Image from "../../components/Common/ImageFluid";
import RichText from "../RichText";
import { StyledWrap } from "../RichText/style";
import valueCheck from "src/images/value-check.svg";

interface Props {
  notificationBar: boolean;
}
interface BundleProps {
  isActive: boolean;
  increaseHeight: boolean;
}

interface QuantityProps {
  isActive: boolean;
  increaseHeight: boolean;
}

interface CaseProps {
  isActive: boolean;
  increaseHeight: boolean;
}
interface BadgeProps {
  isPopular: boolean;
  backgroundColor?: string;
}
interface BundleWrapProps {
  isPopular: boolean;
  isBestValue: boolean;
  isOpened: boolean;
}

export const StyledContainer = styled.div<Props>`
  padding: 2.4rem 2rem 2rem;
  #variant-hide-mobile {
    display: none;
  }

  #free-trial-message {
    display: none;
  }

  // #hideIconsOnEasySleeper {
  //   display: none;
  // }

  @media ${({ theme }) => theme.device.tablet} {
    /* max-width: 41.2rem; */
    max-width: 52%;
    padding: 0 0 0 6.4rem;
    box-sizing: content-box;
    /* position: sticky; */
    #variant-hide-mobile {
      display: block;
    }
    top: ${({ notificationBar }) => (notificationBar ? "14rem" : "9.2rem")};
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 0 2rem 2rem;
  }

  .trustpilot {
    margin: 1.3rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.4rem;

    .text {
      font-size: 1.6rem;
      margin: 5px 5px 0 0;
    }

    strong {
      font-weight: 700;
      margin: 5px 5px 0 5px;
    }

    span {
      margin: 0 0 -5px 0;
    }

    .tp-logo-container {
      height: 20px;
      width: 120px;

      @media ${({ theme }) => theme.device.tablet} {
        width: 100px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .tp-logo {
      height: 20px;
      width: 100px;

      @media ${({ theme }) => theme.device.laptop} {
        margin: -25px 0 0 5px;
      }

      @media ${({ theme }) => theme.device.smTablet} {
        margin: 0 0 5px 0;
      }
      @media ${({ theme }) => theme.device.phone} {
        margin: 0 0 5px 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  #trustpilot-logo {
    display: none;
  }

  .modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    backdrop-filter: blur(3px);
    border-style: solid;
    width: 100%;
    overflow: scroll;
    height: 100%;
    display: flex;

    .modal {
      z-index: 3;
      min-height: 0px;
      min-width: 0px;
      width: 520px;
      box-shadow: rgb(0 0 0 / 15%) 0px 3px 15px 6px;
      background-color: rgb(255, 255, 255);
      margin: auto;
      padding: 4rem 8rem;

      @media ${({ theme }) => theme.device.phone} {
        width: 100%;
        padding: 2rem 5rem;
      }

      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .sezzle-image {
          width: 230px;
          height: 35px;
          margin: -10px 0 0 0;

          img {
            width: 100%;
            opacity: 0.3;
          }
        }

        .klarna-image {
          width: 100px;
          height: 30px;

          img {
            width: 100%;
            opacity: 0.3;
          }
        }

        .close {
          position: absolute;
          z-index: 0;
          min-height: 0px;
          min-width: 0px;
          justify-content: center;
          height: 40px;
          padding-right: 20px;
          right: -50px;
          cursor: pointer;
          top: -1px;
          width: 52px;

          img {
            width: 100%;
          }
        }
      }

      .main-title {
        max-width: 100%;
        margin: 2rem 0px 2rem 0;
        padding: 1px 0px 4px;
        box-sizing: border-box;
        flex-shrink: 0;
        text-rendering: geometricprecision;
        -webkit-font-smoothing: antialiased;
        word-break: break-word;
        text-align: left;
        direction: ltr;
        font-family: 'Effra-Bold';
        font-size: 27px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.2px;
        color: rgb(23, 23, 23);
        text-size-adjust: none;
      }

      .terms {
        max-width: 100%;
        color: rgb(120, 117, 115);
        font-family: 'Effra-Regular';
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-rendering: geometricprecision;
        -webkit-font-smoothing: antialiased;
        text-align: left;
        word-break: break-word;
        margin-bottom: -10px;
        margin-top: -5px;
        padding-bottom: 4px;
        padding-top: 1px;
        text-size-adjust: none;
        margin: 1rem 0;
      }

      .close-btn {
        box-sizing: border-box;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        flex-shrink: 0;
        border-style: solid;
        border-width: 0px;
        position: relative;
        z-index: 0;
        min-height: 0px;
        min-width: 0px;
        padding-top: 0px;
        margin-top: -5px;
        margin-bottom: -6px;
        background-color: black;
        margin: 2rem 0;
        padding: 1.3rem 0;
        cursor: pointer;

        span {
          max-width: 100%;
          color: rgb(255, 255, 255);
          font-family: 'Effra-Medium';
          font-weight: 500;
          font-size: 16px;
          opacity: 1;
          line-height: 20px;
          transition: color 0.2s ease 0s;
          visibility: visible;
          -webkit-font-smoothing: antialiased;
          text-rendering: geometricprecision;
          text-size-adjust: none;
          display: flex;
          justify-content: center;
        }
      }

      .description {
        max-width: 100%;
        color: rgb(23, 23, 23);
        font-size: 16px;
        font-family: 'Effra-Regular';
        font-weight: 400;
        line-height: 25px;
        -webkit-font-smoothing: antialiased;
        text-align: left;
        word-break: break-word;
        margin-bottom: -10px;
        margin-top: -10px;
        padding-bottom: 3px;
        padding-top: 2px;
        text-rendering: geometricprecision;
        text-size-adjust: none;
      }

      .list {
        .li {
          display: flex;
          flex-direction: row;
          margin: 2.8rem 0;

          img {
            width: 43px;
            margin: 0 0 0 7px;
          }

          .square-text {
            position: relative;

            .square {
              width: 10px;
              height: 10px;
              background-color: black;
            }

            .line {
              width: 2px;
              background-color: rgb(240, 238, 235);
              position: absolute;
              height: 206px;
              margin: 0 4px;

              @media ${({ theme }) => theme.device.phone} {
                height: 234px;
              }
            }
          }

          p {
            margin: 0 0 0 1rem;
            max-width: 100%;
            padding-bottom: 4px;
            padding-top: 1px;
            font-family: 'Effra-Medium'
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: rgb(23, 23, 23);
            letter-spacing: -0.2px;
            text-rendering: geometricprecision;
            -webkit-font-smoothing: antialiased;
            white-space: pre-line;
            margin-bottom: -10px;
            margin-top: -5px;
            text-size-adjust: none;
            display: flex;
          }
        }
      }
    }
  }
`;

export const HeadingTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSize["3xl"].mobile};
  line-height: ${({ theme }) => theme.lineHeight["3xl"].mobile};
  color: rgb(0 0 0);

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["3xl"].desktop};
    line-height: ${({ theme }) => theme.lineHeight["3xl"].desktop};
  }
`;

export const Title = styled(Heading)`
  margin-bottom: 1.6rem;
  font-weight: 700;
  font-family: 'Effra-Bold';
  color: ${({ theme }) => theme.colors.dark_blue};
  position: relative;
  top: unset;
  font-size: 2.4rem;
  line-height: 3.2rem;
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0rem;
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
  
`;

export const PriceBox = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  top: unset;
  flex-wrap: wrap;

  #hide-pulsing {
    display: none;
  }
`;

export const KlarnaBox = styled.div`
  border: 0.5px solid #c4c4c4;
  margin: 2rem 0;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;

  .payment-container {
    div {
      display: flex;
      flex-direction: column;
    }
  }

  .clickable {
    text-decoration: underline;
    cursor: pointer;
    line-height: 19px;
    width: 100px;
  }

  @media ${({ theme }) => theme.device.phone} {
  }

  span {
    font-size: 13px;
    line-height: 14.3px;
    font-family: 'Effra-Regular';
    font-weight: ${({ theme }) => theme.fontWeight.regular};

    @media ${({ theme }) => theme.device.phone} {
      font-size: 11px;
    }
    @media ${({ theme }) => theme.device.smPhone} {
      font-size: 9px;
    }

    span {
      margin: 0 0.7rem 0 0;

      strong {
        font-weight: 700;
        margin: 0 0.3rem;
        text-decoration: underline;
        font-family: 'Effra-Bold';
      }
    }

    img {
      width: 40px;
      height: 30px;
      margin: 0 5px -10px 3px;
    }
  }
`;

export const Message = styled.div`
  margin: 22px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
    margin: 8px 0 16px 0;
  }

  .title-icon-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px 0 0;
    @media ${({ theme }) => theme.device.phone} {
      width: 100%;
      align-items: center;
      height: unset;
    }

    img {
      width: 100%;
      height: 100%;
      @media ${({ theme }) => theme.device.phone} {
        width: 5%;
        height: 100%;
      }
    }

    .mobileFree {
      display: none;
      @media ${({ theme }) => theme.device.phone} {
        display: flex;
        padding-left: 0.8rem;
      }
    }
  }

  .description {
    font-family: 'Effra-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0px;
    text-align: left;
    width: 90%;
    color: "#000";
    padding: unset;
    @media ${({ theme }) => theme.device.phone} {
      text-align: center;
      width: 100%;
      padding: 0.3rem 2rem 0 2rem;
    }
  }
  .desktopFree {
    display: contents;
    @media ${({ theme }) => theme.device.phone} {
      display: none;
    }
  }

  .desktopFreeGift {
    display: contents;
    font-size: 16px;
    font-weight: 700;
    @media ${({ theme }) => theme.device.phone} {
      display: none;
    }
  }

  br {
    display: block;
    @media ${({ theme }) => theme.device.phone} {
      display: none;
    }
  }
`;


export const Breed = styled.div`
  font-size: 1.2rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  font-weight: 200;
  z-index: 1;
  position:relative;
  top: unset;

  span:nth-child(3) {
    font-weight: 500;
  }
`;

export const PriceHeading = styled.span`
  font-weight: 600;
  font-size: 25px;
  margin-right: 0.5rem;
  font-family: "Effra-Medium";
  @media ${({ theme }) => theme.device.phone} {
    font-size: 21px;
  }
`;

export const Price = styled(PriceHeading)`
  margin-right: 0.9rem;
  
  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 1.2rem;
  }
`;

export const ComparePrice = styled(PriceHeading)`
  opacity: 0.25;
  text-decoration: line-through;
  
  @media ${({ theme }) => theme.device.phone} {
    text-align: center;
    margin-right: 0.5rem;
  }
`;

export const RatingsBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.1rem;
  cursor: pointer;
  position: relative;
  top: unset;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0.6rem;
  }
`;

export const TopIconList = styled(IconList)`
  margin-top: 1rem;
  position: relative;
  top: unset;
  width: unset;
  margin-bottom: 1rem;

  &#hideIconsOnEasySleeper {
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ${ListItem} {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    text-align: left;
    flex: 1;
    @media ${({ theme }) => theme.device.phone} {
      flex-direction: column;
      text-align: center;
    }
  }

  ${Icon} {
    margin-bottom: 0.5rem;
    margin-right: 0;
    height: 30px;
    max-height: none;

    @media ${({ theme }) => theme.device.tablet} {
      margin-bottom: 0rem;
    }
    @media ${({ theme }) => theme.device.phone} {
      height: 30px;
    }
  }
  ${Paragraph} {
    font-size: 12px;
    margin-left: 0.8rem;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.phone} {
      margin-left: 0rem;
      font-size: 14px;
    }
    @media ${({ theme }) => theme.device.laptop} {
      margin-left: 1.2rem;
      font-size: 14px;
    }
  }
`;

export const StyledRatingDesktop = styled(Rating)`
  margin-right: 0.4rem;
  width: unset;

  @media ${({ theme }) => theme.device.phone} {
    width: 22% !important;
  }
`;

export const TotalReviews = styled.span`
  //text-decoration: underline;
  color: black;
`;

export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 13px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 11px;
    }
  }
`;

export const Description = styled.div`
  // background-color: ${({ theme }) => theme.colors.bg_white};
  width: 100%;
  margin-top: 0.75rem;
  /* margin-bottom: 2.8rem; */

  &#hideOnEasySleeper {
    display: none;
  }
`;

export const AdditionalInformation = styled.a`
  display: block;
  text-decoration: underline;
  color: blue;
  margin-bottom: 2.8rem;
  font-size: 1.4rem;
`;

export const DescriptionFooter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const DescriptionContent = styled(Paragraph)`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
`;

export const StyledBlocks = styled(BlockContent)`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
    
  @media ${({ theme }) => theme.device.phone} {
    font-size: 12px;
  }

  p:empty {
    min-height: 16px;
  }

  p {
    // padding-bottom: 8px !important;
  }
`;

export const Author = styled(Paragraph)`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 1.3rem;
`;

export const DescriptionLink = styled(Link)`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 0.8rem;
  flex-basis: 100%;

  @media ${({ theme }) => theme.device.phone} {
    margin-top: unset;
    flex-basis: unset;
    margin-left: auto;
  }

  @media ${({ theme }) => theme.device.tablet} {
  }
`;

export const DisText = styled.div`
  font-size: 12px;
  color: #FFFFFF;
  background-color: ${({ theme }) => theme.colors.blue_gray};
  border-radius: 4px;
  padding: 0.8rem 1.5rem;
  margin: 0;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 14px;
    padding: 0.8rem 2rem;
    margin: 0;
  }


  &.discount-variant-B {
    border-radius: 40px;
    display: none;
  }
`;

export const DisLabel = styled.div`
  display: none;
  font-size: 10px;
  color: #FFFFFF;
  background-color: ${({ theme }) => theme.colors.blue_gray};
  border-radius: 40px;
  padding: 8px;
  margin: 0  0 0 0.9rem;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 13px;
    padding: 10px;
    margin: 0  0 0 1.2rem;
  }
`;

export const BundleHeader = styled.div`
  display: flex;
  padding: 28px 0 0px;
  margin-bottom: 10px;
  font-family: 'Effra-Regular';
  font-size: 22px;
  align-items: flex-start;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 28px 0 5px;
  }
  span {
    padding: 0 4px 0 12px;
  }

  .header-subheader {
    display: flex;
    flex-direction: column;
    span {
      margin: 6px 0 0 0;
    }
  }
`;

export const QuantityHeader = styled.div`
  display: flex;
  padding: 20px 0 0;
  margin-bottom: 10px;
  font-family: 'Effra-Medium';
  font-size: 22px;
  align-items: flex-start;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 20px 0 5px;
  }
  span {
    padding: 0 4px 0 0;
  }

  .header-subheader {
    display: flex;
    flex-direction: column;
    span {
      margin: 6px 0 0 0;
      font-size: 3.6rem;
      font-weight: 600;
      line-height: 1.15;
      text-transform: capitalize;
      text-align: unset;
      padding: unset;

      @media ${({ theme }) => theme.device.phone} {
        text-align: center;
        padding: 0 2rem 0 2rem;
        font-size: 25px;
      }
    }
    }
  }
`;

export const CaseHeader = styled.div`
  display: flex;
  padding: 28px 0 0px;
  font-family: 'Effra-Regular';
  font-size: 22px;
  align-items: flex-start;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 28px 0 5px;
  }

  span {
    margin-bottom: 13px;
  }

  .header-subheader {
    display: flex;
    flex-direction: column;

    strong {
      font-weight: bold;
    }
  }
`;

export const BundleContainer = styled.div<BundleProps>`
  position: relative;
  overflow: hidden;
  min-height: ${(props) => (props.increaseHeight ? "145px" : "125px")};
`;

export const QuantityContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

export const QuantityCard = styled.div<QuantityProps>`
  position: relative;
  z-index: 0;
  /* overflow: hidden; */
  min-height: ${(props) => (props.increaseHeight ? '145px' : '125px')};
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  margin: 8px 0;
  padding: 20px 12px;
  border: 1px solid black;
  border-radius: 14.2453px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.isActive ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "unset"};
  background-color: ${(props) => (props.isActive ? "#F6F6FA" : "")};
  cursor: pointer;

  &.quantity-variant-B {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 55px 12px 30px;
    display: none;

    .headerVariantB {
      font-size: 24px;
      padding: 0;
      text-align: center;

      @media ${({ theme }) => theme.device.phone} {
        font-size: 16px;
      }
    }
    .priceContainer {
      flex-direction: row;
      padding-top: 2.5px;
      flex-wrap: wrap;
      justify-content: center;

      .bundleOriginPrice {
        font-size: 24px;
        padding-top: 0;
        padding-right: 2.5px;

        @media ${({ theme }) => theme.device.phone} {
          font-size: 16px;
        }
      }

      .bundleDiscountedPrice {
        font-size: 24px;

        @media ${({ theme }) => theme.device.phone} {
          font-size: 16px;
        }
      }
    }

    .saveMoney {
      text-align: center;
      font-size: 18px;
      line-height: 20px;
      padding-top: 0;

      @media ${({ theme }) => theme.device.phone} {
        font-size: 12px;
      }

      span {
        font-style: normal;
      }
    }

    .badge {
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 23px;
      color: ${({ theme }) => theme.colors.bg_white};
      left: auto;
      padding: 7px 10px;
      background-color: ${({ theme }) => theme.colors.light_pink};

      @media ${({ theme }) => theme.device.phone} {
        font-size: 12px;
        padding: 5px 10px;
        border-top-right-radius: 30px;
      }
    }

    .bundleDiscount {
      right: auto;
      left: 10px;
      padding: 10px 20px;
      background-color: ${({ theme }) => theme.colors.blue_gray};

      @media ${({ theme }) => theme.device.phone} {
        padding: 5px 10px;
      }
    }
  }

  div.saveMoney {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: right;
    width: 100%;
    padding-top: 8px;
    span {
      padding: 2px 0;
      font-style: italic;
      opacity: 0.6;
    }

    .freeShippingStyle {
      margin: 0;
      font-size: 12px;
      font-style: normal;
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 20px 0;

    div.saveMoney {
      text-align: center;
    }
  }
`;

export const QuantityWrap = styled.div`
  display: flex;
  padding: 0 10px;
  font-family: 'Effra-Regular';
  color: #0f0923;
  align-items: center;

  @media ${({ theme }) => theme.device.phone} {
    padding: 0 10px;
  }
  &:first-child {
    /* display: grid; */
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
    /* grid-template-columns: 20px 1fr; */
  }
  &:last-child {
    flex-direction: column;
    justify-content: center;
  }
  h2 {
    font-size: 18px;
    padding-bottom: 8px;
    font-weight: normal;
    padding: 10px 0 3px 0;
  }
  p {
    font-size: 18px;
  }
  strong {
    font-weight: 700;
  }
  span {
    font-weight: 400;
  }
  div.WrapContain {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  /* div.bundleDesc {
    padding-left: 22px;
    @media ${({ theme }) => theme.device.phone} {
      padding-left: 10px;
    }
  } */

  div.bundleDiscount {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 35px;
    display: flex;
    align-items: center;
    background-color: #1a1f59;
    border-radius: 40px;
    color: white;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    transform: translateY(-50%);
    @media ${({ theme }) => theme.device.phone} {
      font-size: 12px;
      padding: 0.5rem 0.75rem;
      right: 0px;
    }
  }
  div.bundlePopular {
    background-color: #f97878 !important;
  }
  div.priceContainer {
    display: flex;
    flex-direction: column;
    @media ${({ theme }) => theme.device.phone} {
      padding-right: unset;
    }
    color: #0F0923;
  }
  

  div.bundleDiscountedPrice {
    font-size: 18px;
    font-weight: bold;
    color: #131313;
    @media ${({ theme }) => theme.device.phone} {
      font-size: 16px;
    }
  }
  div.bundleOriginPrice {
    font-size: 22px;
    font-weight: 400;
    color: #898989;
    opacity: 0.6;
    padding-top: 2.5px;
    text-decoration-line: line-through;
  }
  span {
    padding-right: 2.5px;
  }
  .freeShipping {
    margin-left: auto;
    padding: 0px 8px 2.5px;
    background-color: #f97878;
    color: white;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-top: 2px;
  }
`;

export const NewBundleCard = styled.div<BundleProps>`
  position: relative;
  z-index: 0;
  /* overflow: hidden; */
  min-height: ${(props) => (props.increaseHeight ? '145px' : '125px')};
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  margin: 8px 0;
  padding: 20px 12px;
  border: 2px solid #566DA3;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 2.5rem 0;
  background-color: ${(props) => (props.isActive ? '#F6F6FA' : '')};
  cursor: pointer;

  @media ${({ theme }) => theme.device.phone} {
    padding: 20px 0;
  }
`;

export const CaseContainer = styled.div<CaseProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

export const CaseCard = styled.div<CaseProps>`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 56px 1fr;
  align-items: center;
  min-height: ${(props) => (props.increaseHeight ? '90px' : '76px')};
  margin: 8px 0;
  padding: 15px 6px 15px 12px;
  border: 0.3px solid black;
  border-radius: 10px;
  column-gap: 8px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.isActive ? '0px 4px 10px rgba(0, 0, 0, 0.25)' : 'unset'};
  background-color: ${(props) => (props.isActive ? '#F6F6FA' : '')};
  cursor: pointer;

  @media ${({ theme }) => theme.device.phone} {
    padding: 15px 6px 15px 12px;
    grid-template-columns: 47px 1fr;
  }
`;

export const CardDetail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 4px;
`;

export const CaseTitle = styled.h5`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  text-transform: capitalize;
`;

export const CasePrice = styled.span`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`;

export const CaseLink = styled.a`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-decoration: underline;
`;

export const CardImageContainer = styled.div`
  min-width: 56px;
  height: 56px;

  img {
    border-radius: 99999px !important;
  }

  @media ${({ theme }) => theme.device.phone} {
    width: 47px;
    height: 47px;
  }
`;

export const AtcPillow = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  color: #f97878;
  font-size: 18px;

  @media ${({ theme }) => theme.device.phone} {
    font-size: 11px;
  }
`;

export const BundleWrap = styled.div<BundleWrapProps>`
  /* position: relative; */
  display: flex;
  padding: 0 10px;
  font-family: 'Effra-Regular';
  color: #0f0923;
  align-items: center;

  @media ${({ theme }) => theme.device.phone} {
    padding: 0 10px;
  }
  &:first-child {
    /* display: grid; */
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
    /* grid-template-columns: 20px 1fr; */
  }
  &:last-child {
    flex-direction: column;
    justify-content: center;
  }
  h2 {
    font-size: 18px;
    padding-bottom: 8px;
    font-weight: normal;
  }
  p {
    font-size: 18px;
  }
  strong {
    font-weight: 700;
  }
  span {
    font-weight: 400;
  }
  div.WrapContain {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(20px, -55%);
    gap: 6px;
    @media ${({ theme }) => theme.device.phone} {
      justify-content: center;
      transform: translate(0px, -55%);
    }
  }
  /* div.bundleDesc {
    padding-left: 22px;
    @media ${({ theme }) => theme.device.phone} {
      padding-left: 10px;
    }
  } */
  div.bundleDiscount {
    z-index: 10;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.blue_gray};
    border-radius: 4px;
    color: white;
    font-size: 1.4rem;
    padding: 0.6rem 1.5rem;
    font-weight: 400;
    @media ${({ theme }) => theme.device.phone} {
      font-size: 12px;
      padding: 0.5rem 0.75rem;
      display: none;
      right: 15px;
    }
  }
  div.bundlePopular {
    background-color: #f97878 !important;
  }

  div.bundleDesc {
    width: 100%;
  }

  div.bundleTitlePrice {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: space-between;
  }

  h2.productTitlePhone {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    display: none;
    font-family: 'Effra-Bold';

    @media ${({ theme }) => theme.device.phone} { 
      display: flex;
      justify-content: center;
    }
  }

  div.information-button {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  div.information-button-2 {
    position: relative;
    display: none;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    margin-bottom : ${props => props.isOpened ? "1rem" : "0"};
  }
  div.information-button-3 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media ${({ theme }) => theme.device.phone} { 
    div.information-button-2 {
      position: relative;
      display: flex !important;
      flex-direction: column;
      width: 100%;
    }
    div.information-button-3 {
      position: relative;
      display: none;
      flex-direction: column;
      width: 100%;
    }
  }

  div.titleSubtitleBundle {
    width: 54%;
    margin: .65rem 0 0 0;
    
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      text-transform: uppercase;
      display: block;

      @media ${({ theme }) => theme.device.phone} { 
        display: none;
      }
    }
    .bundle-product-wrapper {
      display: flex;
      align-items: flex-start;
      div{
        width: 15px;
        margin-right: 10px;
        margin-top: 5.5px;
        svg {
          width: 15px;
          height: auto;
        }
      }
      
    }

    .bundle-subtitle {
      font-family: 'Effra-Regular';
      font-weight: 300;
      font-size: 16px;
      line-height: 110%;
    }
  }

  div.bundle-info {
    width: 100%;
    display: flex;
    margin-top: ${props => props.isOpened ? "4.2rem" : "0"};
    justify-content: flex-end;
    flex-direction: column;
    @media ${({ theme }) => theme.device.phone} { 
      margin-top: 1rem;
    }
  }

  div.add-to-cart-btn {
    width: 100%;
    
    button{
      height: 4.75rem;
      span{
        font-size: 1.4rem;
      }
    }
    @media ${({ theme }) => theme.device.phone} { 
      width: 100%;
      button{
        span{
          font-size: 14px;
        }
      }
    }
  }
  .bundle-message {
    display: flex;
    margin: 0 0 2rem 0;

    img {
      margin: 0 10px 0 0;
    }

    p {
      font-family: 'Effra-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
      }
    }
  }

  .bundle-product-title {
    font-family: 'Effra-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: nowrap;
    
    &.fs-14 {
      font-size: 14px;
    }


    strong{
      white-space: nowrap;
    }
  }

  
  .bundle-product-price {
    font-family: 'Effra-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin: 0 0 0 1rem;
    color: #DC7F59;
  }

  .bundle-prod-rrp {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Effra-Medium';
    font-style: normal;
  }

  .bundle-prod-you-save {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Effra-Medium';
    font-style: normal;
  }

  .bundleYouSavePrice {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Effra-Medium';
    font-style: normal;
    color: #4BA71F;
    white-space: nowrap;
    margin-left: 4px;
  }

  .bundle-prod-offer-price {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Effra-Medium';
    font-style: normal;
    color: #4F6EA8;
  }

  .bundle-prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 1.25rem  0;
  }

  .bundle-product-subtitle {
    font-weight: 300;
    font-size: 14px;
    color: #898989;
    margin: 0 0 1.5rem 0;
  }
  .bundle-text {
    font-family: 'Effra-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: underline;
    margin: .6rem 0 0 0;
    width: 100%;
    text-align: center;
    align-self: flex-end;
    @media ${({ theme }) => theme.device.phone} { 
      font-size: 12px;
    }
  }

  .bundle-text-opened {
    font-family: 'Effra-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: underline;
    margin: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    @media ${({ theme }) => theme.device.phone} { 
      font-size: 12px;
    }
  }

  .bundle-subtitle {
    font-family: 'Effra-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #0F0923;
  }

  div.priceContainer {
    width: 46%;
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    @media ${({ theme }) => theme.device.phone} {
      padding-right: unset;
    }
    color: #0F0923;
    div.priceContainerWrapper{
      flex-wrap: wrap;
      justify-content: center;

      @media ${({ theme }) => theme.device.phone} {
        flex-wrap: wrap;
        justify-content: center;
      }
      @media ${({ theme }) => theme.device.tablet} {
        display: flex;
        flex-wrap: nowrap;
      }
     
    }
    div.bundleDiscountSecond {
        z-index: 10;
        display: none;
        align-items: center;
        background-color: #566DA3;
        border-radius: 4px;
        color: white;
        font-size: 1.4rem;
        padding: 0.6rem 1.5rem;
        font-weight: 400;
        @media ${({ theme }) => theme.device.phone} {
          font-size: 12px;
          padding: 0.5rem 0.75rem;
          display: flex;
          right: 15px;
        }
    }
  }
  div.bundleDiscountedPrice {
    font-size: 16px;
    font-weight: bold;
    color: #DC7F59;
    margin-left: 4px;
    @media ${({ theme }) => theme.device.phone} {
      font-size: 16px;
    }
  }
  div.bundleOriginPrice {
    font-size: 16px;
    font-weight: 400;
    color: #898989;
    opacity: 0.6;
    text-decoration-line: line-through;
  }
  span {
    padding-right: 2.5px;
  }
  .freeShipping {
    margin-left: auto;
    padding: 0px 8px 2.5px;
    background-color: #f97878;
    color: white;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-top: 2px;
  }
`;

export const Badge = styled.div<BadgeProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 13px;
  border-bottom-right-radius: 13px; 
  width: 100px;
  padding: 0.5rem 0;
  text-align: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  color: #0F0824;
  font-family: 'Effra-Regular';
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem;

  @media ${({ theme }) => theme.device.phone} {
    transform: unset;
    padding: 0.5rem 1rem;
    max-width: unset;
    width: auto;
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 60px;
  }

  @media ${({ theme }) => theme.device.laptop} {
    max-width: 140px;
  }
`;

export const BadgeBundle = styled.div<BadgeProps>`
  position: absolute;
  top: 0;
  right: 4rem;
  border-top-left-radius: 13px;
  border-bottom-right-radius: 13px; 
  width: 100px;
  padding: 0.5rem 0;
  text-align: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  color: #0F0824;
  font-family: 'Effra-Regular';
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem;

  @media ${({ theme }) => theme.device.phone} {
    top: -1rem;
    right: 2rem;
    transform: unset;
    padding: 0.5rem 1rem;
    max-width: unset;
    width: auto;
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 60px;
  }

  @media ${({ theme }) => theme.device.laptop} {
    max-width: 140px;
  }
`;

export const StyledImage = styled(Image)`
  z-index: 1;
  position: relative;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledImages = styled(Image)`
  z-index: 1;
  position: relative;
  max-width: 100%;
  width: 30px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const OkendoReviews = styled.div`

  padding: 0.55rem 0; 
  margin: 0.6rem 0;

  .okeReviews-reviewsSummary {
    display: flex !important;
  }
  .okeReviews-reviewsSummary-starRating {
    width: fit-content;
    margin-right:0.35em !important;
    display: flex !important; 
  }
  .okeReviews-starRating  {
    width: 100% !important;
    height: 100% !important;
  }
  .okeReviews-starRating .okeReviews-a11yText{
    position: relative;
    display: inline;
    overflow: visible;
    padding-left: 11rem;
    font-size: 1.4rem;
    font-family: 'Effra-Regular';
    margin: 0 !important;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.5rem;
    }
    @media ${({ theme }) => theme.device.phone} {
      padding-left: 7.5rem;
      font-size: 1.2rem;
    }
    @media ${({ theme }) => theme.device.smPhone} {
      padding-left: 6rem;
      font-size: 0.85rem;
    }
    @media ${({ theme }) => theme.device.largeDesktop} {
      font-size: 1.3rem;
      padding-left: 9rem;
    }
    @media ${({ theme }) => theme.device.laptopDesktop} {
      font-size: 1.2rem;
      padding-left: 7rem;
    }
  }
  .okeReviews-starRating-indicator {
    position: relative;
    display: contents;
  }
  .okeReviews-reviewsSummary-ratingCount {
    margin: 0 !important;
  }
  .okeReviews-reviewsSummary-ratingCount span:first-child {
    display: none;
  }
  .okeReviews-reviewsSummary-ratingCount .okeReviews-a11yText {
    display: contents;
    font-size: 1.4rem;
    font-family: 'Effra-Regular';
    text-transform: lowercase;
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.5rem;
    }
    @media ${({ theme }) => theme.device.phone} {
      font-size: 1.2rem;
    }
    @media ${({ theme }) => theme.device.smPhone} {
      font-size: 0.85rem;
    }
    @media ${({ theme }) => theme.device.largeDesktop} {
      font-size: 1.3rem;
    }
    @media ${({ theme }) => theme.device.laptopDesktop} {
      font-size: 1.2rem;
    }
  }

  .okeReviews-starRating-indicator-layer {
 
    @media ${({ theme }) => theme.device.tablet} {
      background-size: 71px 21px !important;
    }
    @media ${({ theme }) => theme.device.laptop} {
      background-size: 100px 20px !important;
    }
    @media ${({ theme }) => theme.device.smTablet} {
      background-size: 100px 20px;
    }
    @media ${({ theme }) => theme.device.phone} {
      background-size: 70px 20px !important;
    }
    @media ${({ theme }) => theme.device.smPhone} {
      background-size: 50px 23px !important;
    }
    @media ${({ theme }) => theme.device.largeDesktop} {
      background-size: 81px 20px !important;
    }
    @media ${({ theme }) => theme.device.laptopDesktop} {
      background-size: 66px 21px !important;
    }
  }
`;

export const PulsingGreen = styled.div` 
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 1.5rem;
  .blob {
    background: rgba(109, 189, 2, 1);
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 0 10px 0 0;
    height: 15px;
    width: 15px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
  }
    
  .blob.green {
    background: rgba(109, 189, 2, 1);
    box-shadow: 0 0 0 0 green;
    animation: pulse-green 2s infinite;
  }
  span {
    font-family: 'Effra-Bold';
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #81B52C;
    @media ${({ theme }) => theme.device.phone} {
      font-size: 14px;
    }
  }
  @media ${({ theme }) => theme.device.phone} {
    margin-top: 0.7rem;
    padding: .6rem 0 1rem 1.5rem;
  }
  @keyframes pulse-green {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(109, 189, 2, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(109, 189, 2, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(109, 189, 2, 0);
    }
  }
`;

export const PulsingRed = styled.div` 
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 1.5rem;
  .blob {
    background: red;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 0 10px 0 0;
    height: 15px;
    width: 15px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
  }
    
  .blob.red {
    background: #FF8C00;
    box-shadow: 0 0 0 0 green;
    animation: pulse-red 2s infinite;
  }
  span {
    font-family: 'Effra-Bold';
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #FF8C00;
    @media ${({ theme }) => theme.device.phone} {
      font-size: 14px;
    }
  }
  @media ${({ theme }) => theme.device.phone} {
    margin-top: 0.7rem;
    padding: .6rem 0 1rem 1.5rem;
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.75);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 140, 0, 0) ;
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
`;

export const ProductValues = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 1.5rem 0 1.5rem;
  @media ${({ theme }) => theme.device.phone} {
    margin: 2rem 0 2.5rem;
  }
  &#hideOnOtherProducts {
    display: none;
  }
`
export const Value = styled.div`
  display: flex;
  justify-content: left;
  align-items: baseline;
  // @media ${({ theme }) => theme.device.phone} {
  //   flex-direction: row;
  //   justify-content: center;
  //   img{ 
  //     display: none;
  //   }
  // }

  ${StyledWrap} {
    padding: 0 1rem;
    margin: 0;

    .rich-text {
      margin: 0;
      width: 100%;
       
      p {
        margin: 0.5rem 0 !important;
          // @media ${({ theme }) => theme.device.phone} {
          //   text-align: center;
          // }
      }
   
      // @media ${({ theme }) => theme.device.phone} {
      //   p::before {
      //     content: url(${valueCheck});
      //     padding-right: 1rem;
      //     vertical-align: -9%;
      //   }
      // }
    }
  }
`

export const ImageWrap = styled.div`
  vertical-align: bottom !important;

  img {
    vertical-align: bottom !important;
  }
`
export const NewProductMessage = styled.div`
  padding: 6px 11px;
  color: #FEF9F9;
  font-weight: 400;
  font-size: 12px;
  background: #4F6EA8;
  border-radius: 57px;
  width: fit-content;
  font-family: 'Effra-Regular';
  font-style: normal;
  margin-top: 13px;
  margin-bottom: 18px;
  margin-left: 5px;
  @media ${({ theme }) => theme.device.phone} {
    margin-bottom: 30px;
    }

`

import styled, { css } from 'styled-components';
import Button from 'src/components/Common/Button';
import arrow from 'src/images/select_arrow.svg';
import { Paragraph } from '../Text';

interface FormProps {
  fixed?: boolean;
  modalCollapse?: boolean;
  backgroundColor?: string;
}


interface NumberInputProps {
  isQuantity?: boolean;
}

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 30%;
  height: 5rem;
  border: 1px solid #566DA3;
  justify-content: space-around;
  margin-right: 10px;
  border-radius: 4px;

  @media ${({ theme }) => theme.device.phone} {
  }
`;

export const StyledForm = styled.form<FormProps>`
  &.formInPDP {
    ${({ fixed, modalCollapse }) =>
      fixed &&
      css`
        position: fixed;
        z-index: 2;
        bottom: 0;
        width: 100%;
        padding: 5.5rem 2rem 1.5rem;
        left: 0;
        background-color: ${({ theme }) => theme.colors.bg_white};
        max-height: ${modalCollapse ? '100%' : '120px'};
        transition: max-height 0.25s ease-in;

        ${Quantity}, h1 {
          display: none;
        }
        &.formInOtherPages {
          position: unset;
        }
      `}
    }

    #mobileTestimonial {
      display: none;
    }
`;

export const CheckoutButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
  padding: 0;
`;

export const NumberInput = styled.input<NumberInputProps>`
  -moz-appearance: textfield;
  border: 0;
  padding: 0 0 0 0.9rem;
  min-width: 1.6rem;
  width: 0;
  font-size: 1.65rem;
  box-sizing: content-box;
  background: transparent;
  // background-color: ${(props) => (props.isQuantity ? '#F6F6FA' : '#FFF')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const QuantityButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  line-height: 0;
  
  &:focus{
    outline: none;
  }

  svg {
    width: 2rem;
    height: auto;
  }
`;

export const CaseAtcButtonWrapper = styled.div`
  margin: 20px 0;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.light_pink};
  font-family: 'Effra-Medium';
  width: 100%;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-basis: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0;
    padding-right: 0;
  }

  span.label {
    margin: 0 .5rem 0 0;
    font-size: 1.725rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  img {
    height: 2rem;
  }
`;

export const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-around;

  h1 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .payment-icons{
    margin: 10px auto 0;
    width: 70%;

    @media ${({ theme }) => theme.device.phone} {
      width: 100%;
    }
  }
`;

export const DropdownOffers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 1px 8px #888888;
  margin-top: 1rem;

  div {
    border-bottom: 1px solid #cecece;
    cursor: pointer;
    font-size: 1.35rem;
    padding: 1.4rem;

    span {
      color: #fa8e8a;
    }

    &:hover {
      background-color: #e1e6f0;
    }
  }
`;

export const OfferRow = styled.div<OfferRow>`
  background-color: ${({ active }) => (active ? '#DEE3EE' : '#FFFFFF')};
`;
export const TableOffers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  margin: 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  div.quantity {
    width: 10%;
    display: flex;
    justify-content: flex-start;
  }
  div.quantity-last {
    width: 25%;
    display: flex;
    justify-content: flex-start;
  }
  div.discount {
    color: #e77478;
    width: 20%;
    display: flex;
    justify-content: flex-start;
  }

  div.tableHead {
    background-color: #f4f3f4;
    padding: 1.5rem 1rem;
  }

  div.tableRow {
    padding: 1rem;
    cursor: pointer;
  }

  div.tableFooter {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-weight: bolder;
  }
`;

export const FenixResponse = styled(Paragraph)`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 40px;
  }
  gap: 8px;

  div {
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.phone} {
    gap: 4px;
  }

  font-size: 1.25rem;
  margin-top: 1rem;
`;

export const FenixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ChooseOffer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;

    div.chooseOffer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border: 1px solid #cecece;
      width: 70%;
      padding: 1.6rem 0rem 1.6rem 1.4rem;
      align-items: center;
      border-radius: 1rem;
      font-size: 1.6rem;

      @media ${({ theme }) => theme.device.phone} {
        width: 75%;
      }

      @media ${({ theme }) => theme.device.tablet} {
        width: 70%;
      }
    }

    div {
      span {
        img {
          width: 40px;
          height: 15px;
          transform: translateY(0.2rem) rotate(90deg);
        }
      }
    }
    span {
      margin-right: 1rem;
    }
  }
`;

export const SelectWrapper = styled.div`
  margin-bottom: 2rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    background-image: url(${arrow});
    /* top: 1.1rem; */
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    background-repeat: no-repeat;
  }
`;

export const VariantSelect = styled.select`
  border: 0.1rem solid #c3c1c8;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
  height: 3.5rem;
  padding: 0 0 0 1.3rem;
  width: 100%;
`;

export const OutOfStock = styled.div`
  font-size: 2rem;
  color: red;
`;

export const MobileBundlePopUp = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 50%;
  transform: translateX(50%);
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Effra-Regular';

  img {
    margin-left: 15px;
  }
`;

export const BundleSubheader = styled.div`
  display: flex;
  padding: 0;
  font-family: 'Effra-Regular';
  font-size: 14px;
  line-height: 1;
  align-items: center;
  span {
    padding: 0 4px 0 12px;
  }
`;

export const CaseSubheader = styled.div`
  padding: 0;
  font-family: 'Effra-Regular';
  font-size: 14px;
  line-height: 1;
  align-items: center;

  strong {
    font-weight: bold;
  }
`;

export const InputQuantity = styled.input`
  font-family: 'Effra-Regular';
  font-size: 16px;
  line-height: 1;
  width: 50px;
  border: 0.333842px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3.33842px;
`;

export const OutOfStockTitle = styled.span`
  font-family: 'Effra-Regular';
  display: block;
  font-size: 1.2rem;
  color: red;
`;

export const BuyItNowButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button {
      width: 47%;
    }

    .icons {
      width: 47%;
      height: 35px;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        margin: 7px 0 0 0;
      }

      .image-desktop {
        display: flex;
      }
      .image-mobile {
        display: none;
      }
    }

    @media ${({ theme }) => theme.device.phone} {

      .icons {
        width: 48%;
        height: 40px;

        img {
          margin: 5px 0 0 0;
        }

        .image-desktop {
          display: none;
        }
  
        .image-mobile {
          display: flex;
        }
      }
      .button {
        width: 50%;
      }
     }
`;
import React from "react";
import { useMediaQuery } from "react-responsive";
import { size } from "../Responsive";
import { SectionSettings } from "../../interfaces/sanity";
import { StyledBg, StyledSection } from "./styles";
import { useTheme } from "styled-components";

interface Props {
  Component: any;
  data: {
    sectionSettings?: SectionSettings;
  };
  context?: any;
  id?: string;
}

const SectionModule = ({ Component, data, id, context, ...props }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: size.phone });
  const isTablet = useMediaQuery({ minWidth: size.tablet });
  const theme = useTheme();

  if (data.sectionSettings?.hideSection) return null;

  if (!data.sectionSettings) return <Component data={data} {...props} context={context} />;

  if (data.sectionSettings?.backgroundImage) {
    if (!data.sectionSettings?.noImageMob) {
      return (
        <StyledBg
          Tag="section"
          imageRef={
            isMobile && data.sectionSettings.backgroundImageMobile
              ? data.sectionSettings.backgroundImageMobile.asset._ref
              : data.sectionSettings.backgroundImage.asset._ref
          }
          backgroundColor={
            id === "hero"
              ? theme.colors.font_black
              : data.sectionSettings?.backgroundColor?.value
          }
          id={id ? id : data.sectionSettings?.id}
          position={data.sectionSettings?.bgPosition}
          productPage={context._type === "productsShopify"}
          notificationBar={
            context.notificationBar ? context.notificationBar[0].enabled : false
          }
        >
          <Component data={data} context={context} {...props} />
        </StyledBg>
      );
    }

    return (
      <>
        {isTablet ? (
          <StyledBg
            Tag="section"
            imageRef={
              isMobile && data.sectionSettings.backgroundImageMobile
                ? data.sectionSettings.backgroundImageMobile.asset._ref
                : data.sectionSettings.backgroundImage.asset._ref
            }
            // backgroundColor={data.sectionSettings?.backgroundColor?.value}
            backgroundColor={
              id === "hero"
                ? theme.colors.font_black
                : data.sectionSettings?.backgroundColor?.value
            }
            textColor={data?.sectionSettings?.textColor?.value}
            id={id ? id : data.sectionSettings?.id}
            position={data.sectionSettings?.bgPosition}
            productPage={context._type === "productsShopify"}
            notificationBar={
              context.notificationBar
                ? context.notificationBar[0].enabled
                : false
            }
          >
            <Component data={data} context={context} {...props} />
          </StyledBg>
        ) : (
          <StyledSection
            // backgroundColor={data.sectionSettings?.backgroundColor?.value}
            backgroundColor={
              id === "hero"
                ? theme.colors.font_black
                : data.sectionSettings?.backgroundColor?.value
            }
            textColor={data?.sectionSettings?.textColor?.value}
            id={id ? id : data.sectionSettings?.id}
            productPage={context._type === "productsShopify"}
            notificationBar={
              context.notificationBar
                ? context.notificationBar[0].enabled
                : false
            }
          >
            <Component data={data} context={context} {...props} />
          </StyledSection>
        )}
      </>
    );
  }

  return (
    <StyledSection
      backgroundColor={
        id === "hero"
          ? theme.colors.font_black
          : data.sectionSettings?.backgroundColor?.value
      }
      textColor={data?.sectionSettings?.textColor?.value}
      id={id ? id : data.sectionSettings?.id}
      productPage={context?._type === "productsShopify"}
      notificationBar={
        context?.notificationBar ? context.notificationBar[0].enabled : false
      }
    >
      <Component data={data} context={context} {...props} />
    </StyledSection>
  );
};

export default SectionModule;

import React from 'react';
import ProductList from '../../components/ProductList';
import {
  StyledHeading,
  StyledWrap,
  StyledButton,
  StyledBlocks,
  SectionWrapper
} from "./styles";
import { SectionSettings, Product } from "../../interfaces/sanity";

interface Props {
  data: {
    button: {
      enabled: boolean;
      text: string;
      link: string;
    };
    firstLarge?: boolean;
    showAddToCart?: boolean;
    // showDescription?: boolean;
    products: Product[];
    sectionSettings: SectionSettings;
    heading: any;
  };
}

const ProductListSection = ({ data }: Props) => {
  const { button, heading, products, firstLarge = false, showAddToCart } = data;
  return (
    <SectionWrapper>
      <StyledWrap>
        <StyledBlocks blocks={heading} />
        <ProductList
          products={products}
          firstLarge={firstLarge}
          addToCart={showAddToCart}
          showRating={true}
        />
        {button?.enabled && (
          <StyledButton
            buttonType={button.type ? button.type : 'outline'}
            to={button.link}
          >
            {button.text}
          </StyledButton>
        )}
      </StyledWrap>
    </SectionWrapper>
    
  );
};

export default ProductListSection;
